import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const router = createRouter({
	history: createWebHistory("/dis_vis/"),
	routes: [
		{
			path: "/",
			name: '首页',
			component: () => import('../views/index.vue')
		},
		{
			path: '/inquireRecord',
			name: '查询记录',
			component: () => import('../views/inquireRecord.vue')
		},
		{
			path: '/record',
			name: '历史记录',
			component: () => import('../views/record.vue')
		},
		{
			path: '/toPay',
			name: '收银台',
			component: () => import('../views/toPay.vue')
		},
		{
			path: '/toSFPay',
			name: '司法收银台',
			component: () => import('../views/toSFPay.vue')
		},
		{
			path: '/report',
			name: '背调报告',
			component: () => import('../views/report.vue')
		},
		{
			path: '/judicial',
			name: '司法报告',
			component: () => import('../views/judicial.vue')
		},
		{
			path: '/complain',
			name: '投诉建议',
			component: () => import('../views/complain.vue')
		},
		{
			path: '/caseReport',
			name: '样例报告',
			component: () => import('../views/caseReport.vue')
		},
		{
			path: '/organQuery',
			name: '机构查询',
			component: () => import('../views/organ/query.vue')
		},
		{
			path: '/organResult',
			name: '机构报告',
			component: () => import('../views/organ/result.vue')
		},
		{
			path: '/organInfo',
			name: '机构信息',
			component: () => import('../views/organ/info.vue')
		},
		{
			path: '/specialInfo',
			name: '专业查询',
			component: () => import('../views/special/info.vue')
		},
		{
			path: '/specialTopay',
			name: '专业支付',
			component: () => import('../views/special/toPay.vue')
		},
		{
			path: '/specialResult',
			name: '专业报告',
			component: () => import('../views/special/result.vue')
		},
		{
			path: '/payTrans',
			name: '支付中',
			component: () => import('../views/payTrans.vue')
		},
		{
			path: '/taxResult',
			name: '税务报告',
			component: () => import('../views/tax/result.vue')
		},
		{
			path: '/taxGather',
			name: '税务采集',
			component: () => import('../views/tax/gather.vue')
		},
		{
			path: '/taxQuery',
			name: '税务查询',
			component: () => import('../views/tax/query.vue')
		},
		{
			path: '/taxInfo',
			name: '税务授权',
			component: () => import('../views/tax/info.vue')
		},
		{
			path: '/taxRecord',
			name: '税务查询历史',
			component: () => import('../views/tax/record.vue')
		},
		{
			path: '/taxList',
			name: '税务历史记录',
			component: () => import('../views/tax/list.vue')
		},
		{
			path: '/trans',
			name: '过渡页',
			component: () => import('../views/trans.vue')
		},
		{
			path: '/cashier',
			name: '收银台',
			component: () => import('../views/cashier.vue')
		},
		{
			path: '/car',
			name: '车辆',
			component: () => import('../views/car.vue')
		},
		{
			path: '/debtQuery',
			name: '债务查询',
			component: () => import('../views/debt/query.vue')
		},
		{
			path: '/phoneCard',
			name: '号卡',
			component: () => import('../views/haoka/phoneCard.vue')
		},
		{
			path: '/haokaQuery',
			name: '号卡查询',
			component: () => import('../views/haoka/query.vue')
		},
		{
			path: '/haokaQuerylist',
			name: '订单信息',
			component: () => import('../views/haoka/querylist.vue')
		},
		{
			path: '/haokaOrderDetails',
			name: '订单详情',
			component: () => import('../views/haoka/orderDetails.vue')
		},
		{
			path: '/haokaOrderSuccess',
			name: '订单成功',
			component: () => import('../views/haoka/orderSuccess.vue')
		},
		{
			path: '/haokaShop',
			name: '号卡店铺',
			component: () => import('../views/haoka/shop.vue')
		},
		{
			path: '/haokaShopPoster',
			name: '海报分享',
			component: () => import('../views/haoka/shopPoster.vue')
		}
	],
})


router.beforeEach((to, from, next) => {
	// //拦截跳转机构版 https://yi.suyuanbd.cn

	if (to.path === '/' && to.query.see_type === "phoneCard") { //正式
		next(`/phoneCard?source=${to.query.source}&see_type=${to.query.see_type}&product=${to.query.product}`)
	} else if (to.path === '/haokaOrderDetails' && to.query.see_type === "phoneCard") {
		next(`/phoneCard?source=${to.query.source}&see_type=${to.query.see_type}&product=${to.query.product}`)
	} else if (to.path === '/' && to.query.see_type === "bigSpecialty") {
		next(`/organQuery?source=${to.query.source}&see_type=${to.query.see_type}`)
	} else if (to.path === '/' && to.query.see_type === "specialty") {
		next(`/specialInfo?source=${to.query.source}&see_type=${to.query.see_type}`)

	} else if (to.path === '/' && to.query.see_type === "companyTax") {
		next(`/taxQuery?source=${to.query.source}&see_type=${to.query.see_type}`)
	} else if (to.path === '/' && to.query.see_type === "debt") {
		next(`/debtQuery?source=${to.query.source}&see_type=${to.query.see_type}`)
	}
	else {
		next()
	}

	// //拦截跳转机构版 https://xin.xxycx.com
	// if(to.path === '/' && to.query.see_type === "bigSpecialty"){
	// 	next(`/organQuery?source=${to.query.source}&see_type=${to.query.see_type}`)
	// }else if(to.path === '/' && to.query.see_type === "specialty"){
	// 	next(`/specialInfo?source=${to.query.source}&see_type=${to.query.see_type}`)
	// }else {
	// 	next()
	// }


})


export default router