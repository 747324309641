import { createStore } from 'vuex'

export default createStore({
	state: {
		token: sessionStorage.getItem('token')||'',
		userInfo: '',
	},
	mutations: {
		setToken(state, payload) {
			state.token = payload;
			sessionStorage.setItem('token',payload)
		},
		setUserInfo(state, payload) {
			state.userInfo = payload;
			sessionStorage.setItem('userInfo',payload)
		}
	},
	actions: {
		
	},
});
